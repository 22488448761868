import React, { useState } from "react"
import GitexPopupModal from "../../core/GitexPopupModal"

const GitexSectionNew = () => {
  const [openGitex, setOpenGitex] = useState(false)
  const openGitexModal = () => {
    setOpenGitex(true)
  }
  const closeGitexModal = () => {
    setOpenGitex(false)
  }
  return (
    <>
      {openGitex && (
        <GitexPopupModal
          open={openGitex}
          closeGitexModal={closeGitexModal}
          url={"https://calendly.com/webmob"}
        />
      )}
      <div
        className={`w-full bg-cover bg-center min-h-screen overflow-hidden flex justify-center bg-blue-200`}
      >
        <div className="w-full flex lg:flex-row flex-col justify-center items-center min-h-screen max-w-7xl py-8 md:py-16 lg:py-20 xl:py-20">
          <div className="lg:w-6/12 md:px-12 px-6 md:mt-24 lg:my-0  ">
            <div className="bg-white rounded-md">
              <div className="relative mx-auto rounded-md block">
                <div className="relative flex justify-center rounded-md">
                  <div className="relative flex items-center mx-auto ">
                    <img
                      src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/09/Gitex2024-date.webp"
                      alt="Gitex 2024"
                      title="Gitex 2024"
                      className="h-full"
                      height="100%"
                      width="100%"
                      loading="lazy"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-6/12 w-full flex justify-center items-center lg:px-3 px-4">
            <div className="flex justify-center flex-col ">
              {/* <div className="h-full w-auto flex lg:justify-start justify-center lg:mt-0 mt-12">
                <img
                  // src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/06/Logo-london-tech-week.png"
                  src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/09/Gitex2024-Logo.webp"
                  alt="WebMobTech in Dubai"
                  title="WebMobTech in Dubai"
                  height="110px"
                  width="440px"
                  className="h-full w-auto"
                  loading="lazy"
                />
              </div> */}
              <div className="flex lg:justify-start justify-center">
                <h1
                  dangerouslySetInnerHTML={{
                    __html: `GITEX 2024`,
                  }}
                  className="font-serif font-semibold lg:text-5xl text-3xl tracking-normal pb-8 text-shark-500 lg:text-left text-center"
                />
              </div>
             
              {/* <div className="flex lg:justify-start justify-center">
                <p className="font-semibold lg:text-xl md:text-xl text-lg font-sans text-shark-400 px-2 rounded lg:text-left text-center mb-2">
                We are a trusted technology partner by businesses from 25+ countries with 14+ years. of exp. & a team of 120+ inhouse developers who are well-versed with 25+ latest technologies and delivered 500+ projects successfully.
                </p>
              </div> */}

              <div className="flex lg:justify-start justify-center">
                <p className="font-semibold lg:text-xl md:text-xl text-lg font-sans text-shark-400 px-2 rounded lg:text-left text-center">
                  Let's meet and discuss software ideas, ready-to-launch
                  business solutions & AI elevation in business at GITEX 2024.
                </p>
              </div>
              <div className="flex lg:justify-start justify-center">
                <p className="font-semibold lg:text-xl md:text-xl text-lg font-sans mt-6 text-shark-400 px-2 rounded lg:text-left text-center">
                  <div class="font-semibold lg:text-xl md:text-xl text-lg font-sans text-white px-2 bg-blue-600 rounded lg:text-left text-center">
                  Dubai World Trade Centre
                  </div>
                </p>
              </div>
              <div className="flex lg:justify-start justify-center">
                <p
                  dangerouslySetInnerHTML={{
                    __html: `14 to 18 October 2024`,
                  }}
                  className="font-semibold lg:text-xl md:text-xl text-lg font-sans text-shark-400 mt-6 px-2 rounded lg:text-left text-center"
                />
              </div>
              <div className="flex justify-center lg:justify-start w-full mt-3">
                <button
                  className="mt-4 md:my-8 lg:mt-6 bg-yellow-500 relative px-8 py-3 text-base leading-6 font-semibold text-shark-500  hover:bg-yellow-600 rounded"
                  onClick={openGitexModal}
                >
                  Meet us At Dubai
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GitexSectionNew
